/* our-projects-slider
    ====================================================*/
let ourProjects = $('#js-our-projects');

ourProjects.slick({
    centerMode: true,
    centerPadding: 0,
    slidesToShow: 3,
    arrows: true,
    'prevArrow': $('#js-projects-arrows__prev'),
    'nextArrow': $('#js-projects-arrows__next'),
    responsive: [
        {
            breakpoint: 992,
            settings: {
                centerMode: false,
                slidesToShow: 2
            }

        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 1
            }

        },

    ]
});
/* our-project slider
====================================================*/

/* our-project slider popup
    ====================================================*/
ourProjects.magnificPopup({
    delegate: 'a', // child items selector, by clicking on it popup will open
    type: 'image'
    // other options
});